export default [
  // drivers
  {
    name: "sub_admin_drivers",
    path: "sub-admin/drivers",
    component: () => import("@/views/sub_admin/drivers/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // drivers add
  {
    name: "sub_admin_drivers_add",
    path: "sub-admin/drivers/add",
    component: () => import("@/views/sub_admin/drivers/add"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // drivers edit
  {
    name: "sub_admin_drivers_edit",
    path: "sub-admin/drivers/edit",
    component: () => import("@/views/sub_admin/drivers/edit"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
