export default [
  {
    name: "about_us",
    path: "about_us",
    component: () => import("@/views/admin/about_us"),
    beforeEnter(to, from, next) {
      if (localStorage.getItem("accessToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
];
