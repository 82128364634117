export default [
  {
    name: "sub_admin_complain",
    path: "sub-admin/complain",
    component: () => import("@/views/sub_admin/complain/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
