export default [
    {
      name: "sub_admin_maps",
      path: "sub-admin/maps",
      component: () => import("@/views/sub_admin/maps/all_maps/maps"),
      beforeEnter(to, from, next) {
        if (
          localStorage.getItem("accessToken") &&
          JSON.parse(localStorage.getItem("results")).type == "sub_admin"
        ) {
          next();
        } else {
          next("/");
        }
      },
    },
  ];
  