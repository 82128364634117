import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueFeather from "vue-feather";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib"; //Globally import VTextField
import * as VueGoogleMaps from "vue2-google-maps";
import { api_url } from "./api/static_url";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

Vue.component("v-text-field", VTextField);

Vue.use(VCurrencyField, {
  locale: "en-US",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

Vue.use(VueAxios, axios);
Vue.component("GmapCluster", GmapCluster);

axios.defaults.baseURL = api_url + "/api/web/";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("submitLogout");
      router.push("/");

      // location.reload()
    }

    if (error.response.status === 402) {
      router.push("/paymentRequired");
    }

    return Promise.reject(error);
  }
);

Vue.use(VueFeather);

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyC7wc4bXu5GF8WLLXkNDoGm4qL6Z4RN9Q8',
//     libraries: 'places' // Add additional libraries if needed
//   }
// });

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC7wc4bXu5GF8WLLXkNDoGm4qL6Z4RN9Q8",
    libraries: "places", // Add any additional libraries you need
  },
  // Optional: Customize the map options
  installComponents: true,
});

Vue.config.productionTip = false;
Vue.use(Vuebar);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
