export default [
  {
    name: "sub_admin_cart",
    path: "sub-admin/cart",
    component: () => import("@/views/sub_admin/cart/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },

  // one cart
  {
    name: "sub_admin_cart_one",
    path: "sub-admin/cart/:cart_id",
    component: () => import("@/views/sub_admin/cart/show_one"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
