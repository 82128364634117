export default [
  {
    name: "sub_admin_customers",
    path: "sub-admin/customers/show",
    component: () => import("@/views/sub_admin/customers/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    name: "sub_admin_customers_otp",
    path: "sub-admin/customers/otp",
    component: () => import("@/views/sub_admin/customers/otp"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    name: "sub_admin_customers_PasswordOtp",
    path: "sub-admin/customers/PasswordOtp",
    component: () => import("@/views/sub_admin/customers/PasswordOtp"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
