export default [
    {
      name: "tags",
      path: "tags",
      component: () => import("@/views/admin/tags/show.vue"),
      beforeEnter(to, from, next) {
        if (localStorage.getItem("accessToken")) {
          next();
        } else {
          next("/");
        }
      },
    },
  ];
  