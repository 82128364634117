import sub_admin from "./routes/sub_admin";
import category from "./routes/category";
import brands from "./routes/brands";
import about_us from "./routes/about_us";
import mobile_dashboard from "./routes/mobile_dashboard";
import tags from "./routes/tags";

const admin_routes = [
  // subAdmin
  ...sub_admin,

  // category
  ...category,

  // brands
  ...brands,

  // mobile_dashboard
  ...mobile_dashboard,

  // about_us
  ...about_us,

  // tags
  ...tags,
];

export default admin_routes;
