import sub_admin_home from "./routes/sub_admin_home";
import sub_admin_delegates from "./routes/sub_admin_delegates";
import drivers from "./routes/drivers";
import merchants from "./routes/merchants";
import stores from "./routes/stores";
import items from "./routes/items";
import ads from "./routes/ads";
import customers from "./routes/customers";
import maps from "./routes/maps";
import cart from "./routes/cart";
import itemsCategory from "./routes/items_category";
import notifications from "./routes/notifications";
import complain from "./routes/complain";
import suggestion from "./routes/suggestion";
import orders from "./routes/merchantsOrders";

const sub_admin_routes = [
  ...sub_admin_home,

  // delegates
  ...sub_admin_delegates,

  // drivers
  ...drivers,

  // merchants
  ...merchants,

  // stores
  ...stores,

  // items
  ...items,

  // ads
  ...ads,

  // customers
  ...customers,

  // maps
  ...maps,

  // cart
  ...cart,

  // items category
  ...itemsCategory,

  // notifications
  ...notifications,

  // complain
  ...complain,

  // suggestion
  ...suggestion,

  // orders
  ...orders,
];

export default sub_admin_routes;
