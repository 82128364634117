export default [
  {
    name: "category",
    path: "category",
    component: () => import("@/views/admin/categories/show"),
    beforeEnter(to, from, next) {
      if (localStorage.getItem("accessToken")) {
        next();
      } else {
        next("/");
      }
    },
  },

  // sub_category
  {
    name: "sub_categories",
    path: "category/sub_categories/name/:name/id/:id",
    component: () => import("@/views/admin/categories/sub_categories/show"),
    beforeEnter(to, from, next) {
      if (localStorage.getItem("accessToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
];
