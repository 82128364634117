export default [
  // stores
  {
    name: "sub_admin_stores",
    path: "sub-admin/stores/view",
    component: () => import("@/views/sub_admin/stores/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // stores disabled
  {
    name: "sub_admin_stores_disabled",
    path: "sub-admin/stores/disabled",
    component: () => import("@/views/sub_admin/stores/disabled"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // store details
  {
    name: "sub_admin_stores_details",
    path: "sub-admin/stores/view/details/:id/:name",
    component: () => import("@/views/sub_admin/stores/details"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // stores add
  {
    name: "sub_admin_stores_add",
    path: "sub-admin/stores/view/add",
    component: () => import("@/views/sub_admin/stores/add"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // stores edit
  {
    name: "sub_admin_stores_edit",
    path: "sub-admin/stores/view/edit",
    component: () => import("@/views/sub_admin/stores/edit"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
