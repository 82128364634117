export default [
  // merchants
  {
    name: "sub_admin_merchants",
    path: "sub-admin/merchants",
    component: () => import("@/views/sub_admin/merchants/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // merchants add
  {
    name: "sub_admin_merchants_add",
    path: "sub-admin/merchants/add",
    component: () => import("@/views/sub_admin/merchants/add"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // merchants edit
  {
    name: "sub_admin_merchants_edit",
    path: "sub-admin/merchants/edit",
    component: () => import("@/views/sub_admin/merchants/edit"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
