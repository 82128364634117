export default [
  {
    name: "sub_admin_items",
    path: "sub-admin/items",
    component: () => import("@/views/sub_admin/items/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // add
  {
    name: "sub_admin_items_add",
    path: "sub-admin/items/add",
    component: () => import("@/views/sub_admin/items/add"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // edit
  {
    name: "sub_admin_items_edit",
    path: "sub-admin/items/edit/:id",
    component: () => import("@/views/sub_admin/items/edit"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
