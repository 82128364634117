export default [
  {
    name: "subAdmin",
    path: "subAdmin",
    component: () => import("@/views/admin/subAdmin/show"),
    beforeEnter(to, from, next) {
      if (localStorage.getItem("accessToken")) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    name: "subAdminAdd",
    path: "subAdmin/add",
    component: () => import("@/views/admin/subAdmin/add"),
    beforeEnter(to, from, next) {
      if (localStorage.getItem("accessToken")) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    name: "subAdminEdit",
    path: "subAdmin/edit",
    component: () => import("@/views/admin/subAdmin/edit"),
    beforeEnter(to, from, next) {
      if (localStorage.getItem("accessToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
];
