
export default [
  {
    name: "sub_admin_home",
    path: "sub-admin/home",
    component: () => import("@/views/sub_admin/home"),
    beforeEnter(to, from, next) {
      if (localStorage.getItem("accessToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
];
