import Vue from "vue";
import Router from "vue-router";
// import goTo from "vuetify/es5/services/goto";
import admin_routes from "./constants/admin_routes";
import sub_admin_routes from "./constants/sub_admin_routes";
Vue.use(Router);

const router = new Router({
  // mode: "history",
  // base: process.env.BASE_URL,
  // // This is for the scroll top when click on any router link
  // scrollBehavior: (to, from, savedPosition) => {
  //     let scrollTo = 0;

  //     if (to.hash) {
  //         scrollTo = to.hash;
  //     } else if (savedPosition) {
  //         scrollTo = savedPosition.y;
  //     }

  //     return goTo(scrollTo);
  // },

  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "authentication/boxedlogin",
      // component: () =>
      //     import ("@/layouts/blank-layout/Blanklayout"),
      // children: [{
      //     name: "BoxedLogin",
      //     path: "boxedlogin",
      //     component: () =>
      //         import ("@/views/authentication/BoxedLogin"),
      // }, ]
    },

    {
      path: "/dashboards",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "home",
          path: "home",
          component: () => import("@/views/admin/home/home"),
        },

        // admin_routes
        ...admin_routes,

        // sub_admin_routes
        ...sub_admin_routes,
      ],
    },

    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "boxedlogin",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        // one map
        {
          name: "sub_admin_one_map",
          path: "sub-admin/maps/one_map",
          component: () => import("@/views/sub_admin/maps/oneMap"),
          beforeEnter(to, from, next) {
            if (
              localStorage.getItem("accessToken") &&
              JSON.parse(localStorage.getItem("results")).type == "sub_admin"
            ) {
              next();
            } else {
              next("/");
            }
          },
        },
        {
          name: "AllOrdersMap",
          path: "sub-admin/OrdersMap",
          component: () => import("@/views/sub_admin/cart/allOrdersMap"),
          beforeEnter(to, from, next) {
            if (
              localStorage.getItem("accessToken") &&
              JSON.parse(localStorage.getItem("results")).type == "sub_admin"
            ) {
              next();
            } else {
              next("/");
            }
          },
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },

    // {
    //     path: "*",
    //     component: () =>
    //         import ("@/views/Error"),
    // },
  ],
});

// import NProgress from "nprogress";

// router.beforeResolve((to, from, next) => {
//     // If this isn't an initial page load.
//     if (to.name) {
//         // Start the route progress bar.
//         NProgress.start(800);
//     }
//     next();
// });

// router.afterEach(() => {
//     // Complete the animation of the route progress bar.
//     NProgress.done();
// });

export default router;
