export default [
    {
      name: "sub_admin_items_category",
      path: "sub-admin/items_category",
      component: () => import("@/views/sub_admin/itemsCategory/show"),
      beforeEnter(to, from, next) {
        if (
          localStorage.getItem("accessToken") &&
          JSON.parse(localStorage.getItem("results")).type == "sub_admin"
        ) {
          next();
        } else {
          next("/");
        }
      },
    },
  ];
  